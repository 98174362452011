import { withRouter } from "react-router"
import PageContainer from "../PageContainer";
import React, {useEffect, useState} from "react";
import {get, post} from '../../services/http'
import {Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import LinkButton from "../LinkButton";
import {label} from "../../statuses/poll";
import { Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";


function Poll({match: {params: {id}}}) {
    const [poll, setPoll] = useState({})
    const [error, setError] = useState(null)
    const [addVoters, setAddVoters] = useState(1)

    const fetchPolls = async () => {
        const data = await get(`/api/admin/polls/${id}`)
        setPoll(data)
    }

    useEffect(() => {
        fetchPolls()
    }, [id])

    const handleAddVoters = async () => {
        try {
            await post(`/api/admin/polls/${id}/voters/${addVoters}`)
            await fetchPolls()
        } catch (e) {
            setError(e)
        }
    }

    const handleSetStatus = status => async () => {
        try {
            await post(`/api/admin/polls/${id}/status/${status}`)
            await fetchPolls()
        } catch (e) {
            setError(e)
        }
    }

    const [copied, setCopied] = useState(false)

    return (
        <PageContainer title="Перегляд голосування" subtitle={poll.name}>
            {error &&
                <Alert severity="error">Виникла неочікувана помилка...</Alert>
            }
            <Table>
                <TableRow>
                    <TableCell>Назва</TableCell>
                    <TableCell>{poll.name}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Опис</TableCell>
                    <TableCell>{poll.description}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Дата голосування</TableCell>
                    <TableCell>{poll.startAt}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Статус</TableCell>
                    <TableCell>
                        <b>{label(poll.status)}</b>
                        <Box component="span" ml={2}>
                            {poll.status === "new" &&
                                <Button variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={handleSetStatus("open")}>
                                    Розпочати
                                </Button>
                            }
                            {poll.status === "open" &&
                                <Button variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={handleSetStatus("closed")}>
                                    Закрити
                                </Button>
                            }
                        </Box>
                        {poll.status !== "new" &&
                            <Box component="span" ml={1}>
                                <Button variant="contained"
                                        component={Link}
                                        to={`/admin/polls/${poll.id}/results`}
                                        color="primary"
                                        size="small">
                                    {poll.status === "new" ? "Проміжні результати" : "Результати"}
                                </Button>
                            </Box>
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Створено</TableCell>
                    <TableCell>{poll.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Кількість голосуючих</TableCell>
                    <TableCell>{poll.voters?.length || 0}</TableCell>
                </TableRow>
            </Table>
            <Box display="grid" mt={3} justifyContent="space-between" style={{ gridTemplateColumns: '1fr 1fr' }}>
                <Box>
                    <Typography variant="h5" color="secondary">Кандидати (питання) до голосування:</Typography>
                    <List>
                        {poll.contests?.map((contest, idx) => (
                            <ListItem key={contest.id}>
                                <ListItemText primary={`${idx+1}. ${contest.title}`} secondary={contest.description} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Box>
                    <Typography variant="h5" color="secondary">Учасники голосування:</Typography>
                    <Box>
                        <Typography variant="subtitle2" color="primary">Додати учасників</Typography>
                        <Box display="flex" alignItems="center" mb={2}>
                            <TextField label="Кількість"
                                       type="number"
                                       size="small"
                                       value={addVoters}
                                       onChange={(e) => setAddVoters(e.target.value)} />
                            <Button color="primary" variant="contained" onClick={handleAddVoters} style={{marginLeft: 10}}>
                                Додати
                            </Button>
                        </Box>
                    </Box>
                    {!poll.voters?.length &&
                        <Typography variant="subtitle2" color="textSecondary">Учасників поки немає...</Typography>
                    }
                    <List>
                        {poll.voters?.map((voter, idx) => (
                            <ListItem key={voter.id}>
                                <ListItemText primary={
                                    <>
                                        {idx+1}. <LinkButton onClick={() => {
                                            navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/ballot/${voter.tag}`)
                                            setCopied(true)
                                        }}>
                                            {`${window.location.protocol}//${window.location.host}/ballot/${voter.tag}`}
                                        </LinkButton> &#x2398;
                                    </>
                                }>
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
            <Snackbar open={copied} autoHideDuration={6000} onClose={() => setCopied(false)}>
                <Alert severity="info">
                    Посилання скопійоване
                </Alert>
            </Snackbar>
        </PageContainer>
    )
}


export default withRouter(Poll)