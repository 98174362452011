import React from "react";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


function Success({ children }) {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100%', boxSizing: 'border-box'}} flexDirection="column" p={5}>
            <Box>
                <CheckCircleIcon color="primary" style={{ fontSize: 100 }}/>
            </Box>
            <Typography variant="h3" color="primary" component="div" align="center">
                {children || 'Дякую, ваш голос зараховано!'}
            </Typography>
        </Box>
    )
}

export default Success