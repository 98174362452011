import React, {Component} from "react"
import { get } from "../services/http";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {withStyles} from "@material-ui/core/styles";
import Error from "./Error";
import GlobalProgress from "./GlobalProgress";
import PageContainer from "./PageContainer";
import {withRouter} from "react-router-dom";

class Results extends Component{
    state = {
        results: {},
        loading: true
    }

    async componentDidMount() {
        const {match: {params: {id}}} = this.props
        try {
            const results = await get(`/api/admin/polls/${id}/ballot_results`)
            this.setState({ results, loading: false })
        } catch (e) {
            this.setState({ loading: false, error: true })
        }
    }

    get votesCount() {
        return this.state.results.votesCount || []
    }

    get contestsById() {
        const poll = this.state.results?.poll
        if (!poll) {
            return {}
        }
        const contestsById = {}
        poll.contests.forEach(c => {
            contestsById[c.id] = c
        })

        return contestsById
    }

    get optionsById() {
        const poll = this.state.results?.poll
        if (!poll) {
            return {}
        }
        const optionsById = {}
        poll.contests.forEach(c => {
            c.options.forEach(o => {
                optionsById[o.id] = o
            })
        })

        return optionsById
    }

    render() {
        if (this.state.loading) {
            return <GlobalProgress />
        }

        if (this.state.error) {
            return (
                <Error>
                    Виникла неочікувана помилка... :(
                </Error>
            )
        }

        const poll = this.state.results?.poll || {}
        const options = this.optionsById
        const contests = this.contestsById

        console.log(this.state.results)

        return (
            <PageContainer title={`${poll.name || ''}: Результати голосування`} subtitle="Бюлетені">
                <Typography variant="h4" color="secondary">
                    {poll.title}
                </Typography>
                {this.state.results?.results?.length === 0 &&
                    <Typography variant="subtitle1" color="textSecondary">
                        Результатів поки немає...
                    </Typography>
                }
                {this.state.results?.results?.map((ballot, idx) => (
                    <Box key={ballot.ballotChecksum} px={4} mt={3}>
                        <Typography variant="h6" color="secondary">
                            Бюлетень №{idx+1}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            Код: {ballot.ballotChecksum}
                        </Typography>
                        <Table>
                            <TableBody>
                                {ballot.votes.map(vote => (
                                    <TableRow hover>
                                        <TableCell>{contests[vote.contestId].title}</TableCell>
                                        <TableCell>{contests[vote.contestId].description}</TableCell>
                                        <TableCell>{options[vote.contestOptionId].title}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                ))}
            </PageContainer>
        )
    }
}

const styles = _theme => ({})

export default withRouter(withStyles(styles)(Results))