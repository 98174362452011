import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {useMediaQuery} from "@material-ui/core";
import { useLocation } from 'react-router-dom'


const Header = () => {
    const isPrint = useMediaQuery("print")
    const location = useLocation();
    if (!location.pathname?.startsWith("/admin")) {
        return false
    }

    if (isPrint) {
        return false
    }

    return (
        <AppBar position="static">
            <Toolbar>
                <Link to="/admin">
                    <Typography variant="h6" style={{ color: '#fff', textDecoration: 'none' }}>
                        Голосування ДТЕУ
                    </Typography>
                </Link>
            </Toolbar>
        </AppBar>
    )
}

export default Header