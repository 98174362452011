import React, {useEffect, useState} from "react";
import {Typography, Box} from "@material-ui/core";
import { withRouter } from "react-router"
import { get, post } from '../services/http'
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Error from "./Error";
import Success from "./Success";
import { withStyles } from '@material-ui/core/styles';

function Ballot({match: { params: { voterTag: voterTagRaw } }, ...props}) {
  const voterTag = voterTagRaw.trim()
  const [poll, setPoll] = useState({})
  const [error, setError] = useState(null)
  const [votes, setVotes] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pollData = await get(`/api/polls?id=${voterTag}`)
        setPoll(pollData)
      } catch (error) {
          setError(error)
      }
    }

    fetchData()
  }, [voterTag])

  if (error && error.message === "Duplicate") {
    return <Success>Ваш голос вже зараховано</Success>
  }

  if (error) {
    return <Error>Виникла неочікувана помилка... :(</Error>
  }

  const handleVote = async () => {
    const votesToAdd = []
    Object.entries(votes).forEach(([contestId, contestOptionId]) => {
      votesToAdd.push({
        contestId: parseInt(contestId),
        contestOptionId: parseInt(contestOptionId)
      })
    })

    try {
      await post(`/api/votes?id=${voterTag}`, votesToAdd)
      props.history.push("/success")
    } catch (error) {
      setError(error)
    }
  }

  const handleOptionChange = contest => e => {
    const value = e.target.value
    setVotes(oldVotes => {
      return {...oldVotes, [contest.id]: value}
    })
  }

  return(
    <Box className={props.classes.root}>
      <Typography variant="h4" color="primary">{poll.name}</Typography>
      <Typography variant="h6" color="textSecondary">{poll.description}</Typography>
      <Typography variant="subtitle2" color="textSecondary">{poll.startAt}</Typography>
      {poll.contests?.length > 0 &&
        <Box mt={3}>
          <Box>
            <Box display="flex" flexDirection="column">
              {poll.contests?.map(contest => (
                  <Box>
                    <FormControl component="fieldset" key={contest.id} style={{marginBottom: 15}}>
                      <FormLabel component="legend">
                        <Typography variant="h6" color="secondary">{contest.title}</Typography>
                      </FormLabel>
                      <FormHelperText>
                        <Typography variant="body1" color="textPrimary">{contest.description}</Typography>
                      </FormHelperText>
                      <RadioGroup value={votes[contest.id]}
                                  onChange={handleOptionChange(contest)}>
                        {contest.options.map(o => (
                            <FormControlLabel key={o.id} value={String(o.id)} control={<Radio />} label={o.title} />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
              ))}
            </Box>
            <Button variant="outlined"
                    color="primary"
                    onClick={handleVote}
                    disabled={poll.contests?.length !== Object.keys(votes).length}>
              Проголосувати
            </Button>
          </Box>
        </Box>
      }
    </Box>
  )
}

const styles = theme => ({
  root: {
    padding: '40px 80px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 15px 30px',
    }
  }
})

export default withStyles(styles)(withRouter(Ballot))