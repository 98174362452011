import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";


function Error() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh', boxSizing: 'border-box'}} flexDirection="column" p={5}>
            <CircularProgress size={100} />
        </Box>
    )
}

export default Error