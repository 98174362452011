import PageContainer from "../PageContainer";
import React, {useEffect, useState} from "react";
import {get} from "../../services/http";
import MuiList from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import StopIcon from '@material-ui/icons/Stop';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {label} from "../../statuses/poll";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import PollIcon from '@material-ui/icons/Poll';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FiberNewIcon from '@material-ui/icons/FiberNew';

export default function List() {
    const [polls, setPolls] = useState([])
    useEffect(async () => {
        const polls = await get(`/api/admin/polls`)
        setPolls(polls)
    }, [])


    const pollIcon = poll => {
        switch (poll?.status) {
            case "new":
                return <FiberNewIcon />
            case "open":
                return <HowToVoteIcon />
            case "closed":
                return <StopIcon />
        }

        return <PollIcon />
    }

    return (
        <PageContainer title="Голосування"
                       actions={<Button color="primary" variant="contained" component={Link} to="/admin/polls/new">Створити</Button>}>
            <MuiList>
                {polls?.map(poll => (
                    <ListItem button component={Link} to={`/admin/polls/${poll.id}`}>
                        <ListItemIcon>
                            {pollIcon(poll)}
                        </ListItemIcon>
                        <ListItemText primary={`${poll.name} `}
                                      secondary={label(poll.status)} />
                        {poll.status !== "new" &&
                            <ListItemSecondaryAction>
                                <Tooltip title="Результати">
                                    <IconButton edge="end" aria-label="delete" component={Link} to={`/admin/polls/${poll.id}/results`} color="secondary">
                                        <PollIcon />
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                ))}
            </MuiList>
        </PageContainer>
    )
}