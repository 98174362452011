import PageContainer from "../PageContainer";
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import {DatePicker} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {MenuItem, Typography} from "@material-ui/core";
import FormContainer from "../FormContainer";
import {post} from "../../services/http";
import moment from "moment";
import { withRouter } from "react-router"
import FileUpload from "../FileUpload";
import Error from "../Error";
import Alert from '@material-ui/lab/Alert';
import Select from "../Select";

const options = [
    ["Згоден", "Не Згоден"],
    ["За", "Проти", "Утримався"],
    ["За", "Проти"],
    ["Так", "Ні"],
];

function New({ history }) {
    const { register, handleSubmit, control, watch, errors } = useForm(
        {defaultValues: {options: options[0]}}
    )
    const [error, setError] = useState(null)

    const save = async ({file, startAt, ...data}) => {
        try {
            const buffer = await file[0].arrayBuffer()
            const res = await post("/api/admin/polls", {
                ...data,
                file: bufferToBase64(buffer),
                startAt: moment(startAt).format("YYYY-MM-DD")
            })
            history.push(`/admin/polls/${res.id}`)
        } catch (e) {
            console.error(e)
            console.log(e.result.status)
            setError(e)
        }
    }

    if (error && error.result?.status !== 400) {
        return <Error>Виникла неочікувана помилка... :(</Error>
    }

    const name = watch("name")
    const file = watch("file")

    return (
        <PageContainer title="Нове голосування" subtitle={name}>
            <FormContainer onSubmit={handleSubmit(save)}>
                {error && error.result?.status === 400 &&
                    <Alert severity="error">Дані введено невірно</Alert>
                }
                {error && error.result?.status !== 400 &&
                    <Alert severity="error">Виникла неочікувана помилка... :(</Alert>
                }
                <TextField name="name"
                           label="Назва"
                           inputRef={register({required: true, maxLength: 255})}
                           variant="standard"
                           helperText={errors.name ? errorToString(errors.name) : null}
                           error={errors.name}
                           fullWidth />
                <TextField name="description"
                           label="Опис"
                           inputRef={register({required: true})}
                           multiline
                           rows={2}
                           helperText={errors.description ? errorToString(errors.description) : null}
                           error={errors.description}
                           fullWidth />
                <Controller as={DatePicker}
                            name="startAt"
                            label="Дата голосування"
                            fullWidth
                            format="DD/MM/YYYY"
                            control={control} />
                <Select name="options"
                        label="Варіанти відповідей"
                        control={control} >
                    {options.map(option => (
                        <MenuItem value={option} key={option}>{option.join((" / "))}</MenuItem>
                    ))}
                </Select>
                <Box>
                    <Typography variant="subtitle1" color="textSecondary" style={{ marginRight: 20, marginBottom: 5 }}>
                        Завантажте excel файл з питаннями/кандидатами: <a href="/files/example.xlsx">(приклад)</a>
                    </Typography>
                    <FileUpload inputRef={register({required: true})}
                                error={errors.file}
                                helperText={errors.file ? errorToString(errors.file) : null}
                                fileName={file ? file[0]?.name : null}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.xls,.xlsx"
                                name="file"/>
                </Box>
                <Button type="submit" variant="contained" color="secondary">Створити</Button>
            </FormContainer>
        </PageContainer>
    )
}

const errorToString = error => {
    if (error.type === "required") {
        return "Це поле обов'язкове"
    }

    return "Невірне значення"
}

const bufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array( buffer );
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return btoa(binary);
}

export default withRouter(New)