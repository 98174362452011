import React, {Component} from 'react'
import {withStyles} from "@material-ui/core/styles"

export class LinkButton extends Component {
    render = () => {
        const {classes, disabled, type,...props} = this.props
        let className = `${classes.root}`
        if (disabled) {
            className += ` ${classes.disabled}`
        }

        return (
            <button type={type || 'button'} {...props} className={className} />
        )
    }
}

const styles = theme => ({
    root: {
        border: 'none',
        background: 'transparent',
        color: theme.palette.primary.main,
        display: 'inline',
        appearance: 'none',
        textAlign: 'left',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        padding: 0,
        margin: 0,
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:focus': {
            border: 'none',
            background: 'transparent',
            outline: 'none',
        }
    },
    disabled: {
        textDecoration: 'none',
        color: 'inherit',
        cursor: 'auto',
    }
})

export default withStyles(styles)(LinkButton)