import React from "react";
import Box from "@material-ui/core/Box";
import {withStyles} from "@material-ui/core/styles";


function FormContainer({ onSubmit, children, classes }) {
    return (
        <form onSubmit={onSubmit}>
            <Box className={classes.wrapper}>
                {children}
            </Box>
        </form>
    )
}

const styles = theme => ({
    wrapper: {
        '& > *': {
            marginBottom: 15
        }
    }
})

export default withStyles(styles)(FormContainer)