import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Ballot from './components/Ballot'
import Success from "./components/Success"
import Results from './components/Results'
import List from "./components/polls/List";
import Poll from "./components/polls/Poll";
import New from "./components/polls/New";
import moment from "moment"
import "moment/locale/uk"
import ResultsSingle from "./components/ResultsSingle";
import Header from "./components/Header";
import BallotResults from "./components/BallotResults";

moment.locale("uk")

function App() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="App" style={{ height: '100%' }}>
          <Router>
            <Header />
            <Switch>
              <Route path="/ballot/:voterTag">
                <Ballot />
              </Route>
                <Route path="/success">
                    <Success />
                </Route>
                <Route path="/admin/polls/new">
                    <New />
                </Route>
                <Route path="/admin/polls/:id/results">
                    <ResultsSingle />
                </Route>
                <Route path="/admin/polls/:id/ballot_results">
                    <BallotResults />
                </Route>
                <Route path="/admin/polls/:id">
                    <Poll />
                </Route>
                <Route path="/admin">
                    <List />
                </Route>
            </Switch>
          </Router>
        </div>
    </MuiPickersUtilsProvider>
  );
}

export default App;
