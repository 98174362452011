import FormControl from "@material-ui/core/FormControl";
import {FormHelperText, InputLabel, Select as MUISelect} from "@material-ui/core";
import { Controller } from "react-hook-form";


const Select = (props) => {
    const {label, error, classes, helperText, fullWidth, name, control, ...rest} = props;
    return (
        <FormControl error={error} fullWidth={fullWidth}>
            <InputLabel>{label}</InputLabel>
            <Controller as={<MUISelect {...rest} />} name={name} control={control} />
            {helperText &&
                <FormHelperText>{helperText}</FormHelperText>
            }
        </FormControl>
    )
}

export default Select;