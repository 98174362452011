
export const label = status => {
    switch (status) {
        case "new":
            return "Нове голосування (закрите)"
        case "open":
            return "Голосування відкрите"
        case "closed":
            return "Голосування закрите"
    }
    return status
}