import React, {Component} from "react"
import { Bar } from '@reactchartjs/react-chart.js'
import { get } from "../services/http";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {withStyles} from "@material-ui/core/styles";
import Error from "./Error";
import GlobalProgress from "./GlobalProgress";
import PageContainer from "./PageContainer";
import {withRouter} from "react-router-dom";

class Results extends Component{
    state = {
        results: {},
        loading: true
    }

    async componentDidMount() {
        const {match: {params: {id}}} = this.props
        try {
            const results = await get(`/api/admin/polls/${id}/results`)
            this.setState({ results, loading: false })
        } catch (e) {
            this.setState({ loading: false, error: true })
        }
    }

    get votesCount() {
        return this.state.results.votesCount || []
    }

    render() {
        if (this.state.loading) {
            return <GlobalProgress />
        }

        if (this.state.error) {
            return (
                <Error>
                    Виникла неочікувана помилка... :(
                </Error>
            )
        }

        const totals = {}
        const votesPerOption = {}
        this.votesCount.forEach(({contestId, votes}) => {
            if (!totals[contestId]) {
                totals[contestId] = 0
            }
            totals[contestId] += votes
        })

        this.votesCount.forEach(({contestId, contestOptionId, votes}) => {
            const totalPerContest = totals[contestId]
            votesPerOption[contestOptionId] = {
                votes,
                percent: (votes / totalPerContest * 100).toFixed(2)
            }
        })

        const status = this.state.results.poll?.status === "closed" ? "Фінальні" : "Поточні"
        const title = `${status} результати голосування`

        const optionResults = {}
        this.state.results?.poll?.contests.forEach(c =>
            c.options.forEach(o => {
                if (!optionResults[o.title]) {
                    optionResults[o.title] = []
                }
                optionResults[o.title].push(votesPerOption[o.id]?.votes ?? 0)
            })
        )

        const labelToColor = label => {
            switch (label) {
                case "За":
                    return "rgb(75,192,122)"
                case "Проти":
                    return "rgb(255,48,93)"
                case "Утримався":
                    return "rgb(243,215,130)"
            }
        }

        const chart = {
            labels: this.state.results?.poll?.contests?.map(c => c.title),
            datasets: Object.entries(optionResults).map(([label, votes]) => ({
                label,
                data: votes,
                backgroundColor: labelToColor(label)
            }))
        }

        const chartOptions = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1
                        },
                    },
                ]
            },
        }


        return (
            <PageContainer title={title} subtitle={this.state.results?.poll?.name}>
                <Bar data={chart} options={chartOptions} />
                {this.state.results?.poll?.contests?.map(contest => (
                    <Box key={contest.id} px={4} mt={3}>
                        <Typography variant="h6" color="secondary">
                            {contest.title}
                        </Typography>
                        <Table>
                            <TableBody>
                                {contest.options.map(option => (
                                    <TableRow hover>
                                        <TableCell>{option.title}</TableCell>
                                        <TableCell>{votesPerOption[option.id]?.votes || 0} голосів</TableCell>
                                        <TableCell>{votesPerOption[option.id]?.percent || "0.00"}%</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                ))}
            </PageContainer>
        )
    }
}

const styles = _theme => ({})

export default withRouter(withStyles(styles)(Results))