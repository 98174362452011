import React from "react";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';


function Error({ children }) {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh', boxSizing: 'border-box'}} flexDirection="column" p={5}>
            <Box>
                <CancelIcon color="error" style={{ fontSize: 100 }}/>
            </Box>
            <Typography variant="h3" color="error" component="div" align="center">
                {children}
            </Typography>
        </Box>
    )
}

export default Error