import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import {useMediaQuery} from "@material-ui/core";


function PageContainer({ children, title, subtitle, actions, classes }) {
    const isPrint = useMediaQuery("print")

    if (isPrint) {
        return (
            <Box className={classes.root}>
                <Box>
                    <Typography variant="h5" color="primary">{title}</Typography>
                    <Typography variant="h6" color="textSecondary">{subtitle}</Typography>
                    <Box>
                        {children}
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box className={classes.root}>
            <Card raised>
                <CardHeader title={<Typography variant="h5" color="primary">{title}</Typography>}
                            subheader={subtitle}
                            action={actions} />
                <CardContent>
                    <Box>{children}</Box>
                </CardContent>
            </Card>
        </Box>
    )
}

const styles = theme => ({
    root: {
        padding: '40px',
        [theme.breakpoints.down('sm')]: {
            padding: '15px',
        }
    }
})

export default withStyles(styles)(PageContainer)