

export async function get(url) {
  const res = await fetch(url)

  if (!res.ok && res.status === 409) {
    throw new Error("Duplicate")
  } else if (!res.ok) {
    throw new Error(`HTTP GET request ${url} failed with status ${res.status}`)
  }
  return await res.json()
}

export async function post(url, data) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })

  if (!res.ok) {
    throw new HttpError(`HTTP POST request ${url} failed with status ${res.status}`, res)
  }

  const contentType = res.headers.get("content-type")
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return await res.json()
  } else {
    return await res.text()
  }

}

class HttpError extends Error {
  constructor(message, result) {
    super(message)
    this.result = result
  }
}
