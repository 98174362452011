import React, {Component} from "react"
import { get } from "../services/http";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {withStyles} from "@material-ui/core/styles";
import Error from "./Error";
import GlobalProgress from "./GlobalProgress";
import PageContainer from "./PageContainer";
import {Link, withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";

class Results extends Component{
    state = {
        results: {},
        loading: true
    }

    async componentDidMount() {
        const {match: {params: {id}}} = this.props
        try {
            const results = await get(`/api/admin/polls/${id}/results`)
            this.setState({ results, loading: false })
        } catch (e) {
            this.setState({ loading: false, error: true })
        }
    }

    get votesCount() {
        return this.state.results.votesCount || []
    }

    render() {
        if (this.state.loading) {
            return <GlobalProgress />
        }

        if (this.state.error) {
            return (
                <Error>
                    Виникла неочікувана помилка... :(
                </Error>
            )
        }

        const totals = {}
        const votesPerOption = {}
        this.votesCount.forEach(({contestId, votes}) => {
            if (!totals[contestId]) {
                totals[contestId] = 0
            }
            totals[contestId] += votes
        })

        this.votesCount.forEach(({contestId, contestOptionId, votes}) => {
            const totalPerContest = totals[contestId]
            votesPerOption[contestOptionId] = {
                votes,
                percent: (votes / totalPerContest * 100).toFixed(2)
            }
        })

        const optionResults = {}
        this.state.results?.poll?.contests.forEach(c =>
            c.options.forEach(o => {
                if (!optionResults[o.title]) {
                    optionResults[o.title] = []
                }
                optionResults[o.title].push(votesPerOption[o.id]?.votes ?? 0)
            })
        )

        const poll = this.state.results?.poll || {}

        return (
            <PageContainer title={`${poll.name || ''}: Результати голосування`}
                           subtitle={poll.description}
                           actions={
                               <Button component={Link} to={`/admin/polls/${poll.id}/ballot_results`} color="secondary" variant="contained">
                                   Детальні результати
                               </Button>
                           }>
                <Typography variant="h4" color="secondary">
                    {poll.title}
                </Typography>
                {this.state.results?.poll?.contests?.map(contest => (
                    <Box key={contest.id} px={4} mt={3}>
                        <Typography variant="h6" color="secondary">
                            {contest.title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {contest.description}
                        </Typography>
                        <Table>
                            <TableBody>
                                {contest.options.map(option => (
                                    <TableRow hover>
                                        <TableCell>{option.title}</TableCell>
                                        <TableCell>Кількість голосів: {votesPerOption[option.id]?.votes || 0}</TableCell>
                                        <TableCell>{votesPerOption[option.id]?.percent || "0.00"}%</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                ))}
            </PageContainer>
        )
    }
}

const styles = _theme => ({})

export default withRouter(withStyles(styles)(Results))